import "./App.css";
import Header from "./Header";
import Footer from "./Footer";
import Toast from "./Toast";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Header />
        <Toast />
        <Footer />
      </header>
    </div>
  );
}

export default App;
