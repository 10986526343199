export default function Footer() {
  return (
    <div className="content-center p-2">
      <div className="absolute bottom-10 left-0 right-0 text-center text-black dark:text-white">
        <p className="text-xs text-gray-500 ">
          © Copyright 2023 | Made in the USA
        </p>
      </div>
    </div>
  );
}
